import time from "src/assets/time.png";
import delivery from "src/assets/delivery.png";
import multiple_stores from "src/assets/multiple_stores.png";
import products from "src/assets/products.png";
import voucher from "src/assets/voucher.png";

export const TALK_ABOUT_THIRST = [
  { title: "A wide range of products", icon: products.src },
  { title: "Find the best deals online", icon: voucher.src },
  { title: "Multiple stores to choose from", icon: multiple_stores.src },
  { title: "Order any day, any time", icon: time.src },
  { title: "Delivered right to your doorstep", icon: delivery.src },
];
