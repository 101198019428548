import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import { useRootContext } from "src/contexts/RootContext";
import { GiPlainCircle } from "react-icons/gi";
import Slider from "react-slick";
import jwt from "jsonwebtoken";
import { USER_TOKEN } from "lib/Constants";
import verifyToken from "src/modules/verifyToken";
import { onClickBanner } from "lib/EventTracker";
import { isEmpty } from "lodash";

const HomepageCarouselBanners = ({ banners }) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const rootContext = useRootContext();
  const [currentSlide, setCurrentSlide] = useState();

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (prev, next) => setCurrentSlide(next),
    customPaging: (i) => (
      <Icon
        as={GiPlainCircle}
        boxSize="10px"
        color={i === currentSlide ? "#117BD4" : "#E9F2FA"}
      />
    ),
  };

  return (
    <>
      {banners && !isEmpty(banners) && (
        <Container minW="100%" px={["0px", "60px"]} pt="10px" py={0}>
          <VStack w="100%" p={0}>
            <Box w="100%" h="100%">
              <Slider {...bannerSettings}>
                {!isEmpty(banners) &&
                  banners
                    ?.filter((x) => x.isValid === true)
                    ?.map((data) => (
                      <>
                        {data?.isValid && (
                          <Container
                            as="button"
                            h="100%"
                            minW="100%"
                            key={data.bannerOrder}
                            onClick={() =>
                              onClickBanner(
                                data?.bannerLink ?? "",
                                data?.bannerImage,
                                "Homepage Carousel Banners Top"
                              )
                            }
                            _hover={{ border: "none", bg: "white" }}
                            _focus={{ border: "none", bg: "white" }}
                            _active={{ border: "none", bg: "white" }}
                            px={0}
                          >
                            <Image
                              src={data.bannerImage}
                              alt=""
                              w="100%"
                              h="auto"
                              objectFit="cover"
                            />
                          </Container>
                        )}
                      </>
                    ))}
              </Slider>
            </Box>
          </VStack>
        </Container>
      )}
    </>
  );
};

export default HomepageCarouselBanners;
