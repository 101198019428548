import { Box, Text, VStack, Link, Icon } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import NextLink from "next/link";
import Slider from "react-slick";
import ProductCard from "src/components/store/storeProducts/templates/shared/ProductCard";
import { useRootContext } from "src/contexts/RootContext";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import getPriceRange from "src/modules/getPriceRange";
import { BESTSELLER_TAG, NEW_TAG } from "lib/Constants";
import { HiArrowNarrowRight } from "react-icons/hi";
import StoreCard from "../cards/StoreCard";
import { useRouter } from "next/router";

export default function StoresSlider({ label, stores }) {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isDesktopSM = useMediaQuery("(min-width: 1024px)");
  const router = useRouter();

  const [isDragging, setIsDragging] = useState(false);

  const slidesToShow = useMemo(() => {
    if (isDesktop == true) {
      return stores?.length < 6 ? stores?.length : 6;
    } else if (isDesktopSM) {
      return stores?.length < 4 ? stores?.length : 4;
    } else {
      return stores?.length < 2 ? stores?.length : 2;
    }
  }, [stores?.length, isDesktop, isDesktopSM]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 3,
    slidesToShow: slidesToShow,
    arrows: isDesktop ? true : false,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  return (
    <VStack w="100%" py={2} px={["12px", 0]}>
      <Box w="full" className="flex justify-between items-center" pb={3}>
        <Link
          color="#214151"
          fontWeight={500}
          fontFamily="CeraPro"
          fontSize={["20px", "22px"]}
          lineHeight="24px"
          textAlign="left"
          className="cursor-pointer"
          href="/collections/stores"
          _focus={{
            outline: "none",
            bg: "transparent",
          }}
          _active={{
            bg: "transparent",
          }}
        >
          {label}
        </Link>
        <Box placeSelf="flex-center" className="pr-2">
          <Link
            fontFamily="CeraPro"
            fontWeight={500}
            fontSize={["14px", "16px"]}
            color="#117BD4"
            as={NextLink}
            href="/collections/stores"
            _focus={{ bg: "white", textDecoration: "none" }}
            _active={{ bg: "white", textDecoration: "none" }}
            _hover={{ bg: "white", textDecoration: "none" }}
          >
            <div className="flex justify-center items-center gap-1">
              <div className="whitespace-nowrap">See all</div>
              <Icon as={HiArrowNarrowRight} boxShadow={8} color="#117BD4" />
            </div>
          </Link>
        </Box>
      </Box>

      <Box w="100%">
        <Slider {...settings}>
          {stores.map((x) => {
            return (
              <StoreCard {...x} key={x.store_id} isDragging={isDragging} />
            );
          })}
        </Slider>
      </Box>
    </VStack>
  );
}
