import { Box, Center, Heading, Image, Text, VStack, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useAppDispatch } from 'src/app/hooks';
import { useRootContext } from 'src/contexts/RootContext';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import sortCategories from 'src/modules/sortCategories';

export default function CategoryPicker({ slidesToShowValue }) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const rootContext = useRootContext();
  const { category } = router.query;
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const [sortedList, setSortedList] = useState();
  const _categories = rootContext?.categories;
  const _category = rootContext?.category;
  const _categoryProducts = rootContext?.products 

  const onCategoryClick = () => {
    localStorage.removeItem('last_selected_origin_filter');
    localStorage.removeItem('last_selected_subcat_filter');
    localStorage.removeItem('last_page');
    localStorage.removeItem('last_off_set');
    return true;
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    arrows: isDesktop ? true : false,
    slidesToShow: slidesToShowValue,
    swipeToSlide: true,
    speed: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  useEffect(() => {
    if (!sortedList || sortedList?.length == 0) { //load once
      let sortedCategories = sortCategories([...(_categories)]);
      setSortedList(sortedCategories)
    }
  }, [_categories])

  return (
    <Box w='100%'>
      {_categoryProducts &&
        <Heading
          as='h1'
          fontWeight={500} 
          fontFamily='CeraPro' 
          fontSize={['22px','24px']} 
          color='#214151' 
          w='100%' 
          textAlign='left' 
          pb='24px'
        >
          {_category.name}
        </Heading>
      }

      {sortedList && sortedList.length != 0 && 
        <Box w='100%'>
          <Slider {...settings}>
            {sortedList.map((data) => {
              return (
                <Link href={`/${data.slug}`} key={data.slug} onClick={() => onCategoryClick()}>
                  <VStack
                    w='100%'
                    key={data.id}
                    borderRadius={8}
                    px={3}
                  >
                    <Center>
                      <Image 
                        w={['100%','80px']} 
                        h={['70px','90px']} 
                        src={data.image_url} 
                        placeSelf='center' 
                        alt={data.name} 
                        objectFit='fill'
                      />
                    </Center>
                    
                    <Text 
                      fontFamily='CeraPro' 
                      fontSize={['14px', '18px']} 
                      placeSelf='center' 
                      fontWeight={500} 
                      lineHeight='18px' 
                      color='#214151' 
                      textAlign='center'
                      pb={2}
                      borderBottom={data.name.toLowerCase().replaceAll(" ", "-") == category ? '2px solid #117BD4' : 'none'}
                    >
                      {data.name}
                    </Text>
                  </VStack>
                </Link>
              )
            })}
          </Slider>
        </Box>
      }
    </Box>
  );
};
