const sortCategories = (categories) => {
  const isNoOrdering = categories.filter((i) => i.category_order === null);
  if (isNoOrdering.length !== 0) {
    categories.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    categories.sort((a, b) => a.category_order - b.category_order)
  }

  return categories;
}

export default sortCategories;