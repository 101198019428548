import {
  Box,
  Container,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SPONSORED_PRODUCTS_TAG } from "lib/Constants";
import NextLink from "next/link";
import { useMemo } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import Slider from "react-slick";
import ProductCard from "src/components/store/storeProducts/templates/shared/ProductCard";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import getPriceRange from "src/modules/getPriceRange";
import cn from "classnames";
import { isEmpty } from "lodash";

const ProductRows = ({ products, title, slug, isNew }) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const productsCount = 6;

  const slidesToShow = useMemo(() => {
    if (isDesktop == null) return;
    if (isDesktop == true) {
      return products?.length < productsCount
        ? products?.length
        : productsCount;
    } else {
      return products?.length < 2 ? products?.length : 2;
    }
  }, [products, isDesktop]);

  const sponsoredSettings = {
    dots: false,
    infinite: true,
    speed: 3,
    slidesToShow: slidesToShow,
    arrows: isDesktop == true ? true : false,
    slidesToScroll: isDesktop == true ? 2 : 1,
    swipeToSlide: true,
  };

  const path = isNew ? "featured" : "sponsored";

  return (
    <>
      {products && !isEmpty(products) && (
        <Container minW="100%" px={["12px", "60px"]} pt={"40px"} pb={"0px"}>
          <VStack w="100%" py={2} spacing={0}>
            <Box w="full" className="flex justify-between items-center" pb={3}>
              <HStack
                placeSelf="flex-start"
                justifyContent="center"
                spacing={5}
              >
                <Text
                  color="#214151"
                  fontWeight={500}
                  fontFamily="CeraPro"
                  fontSize={["20px", "20px", "20px", "22px"]}
                  w="100%"
                  textAlign="left"
                >
                  {title}
                </Text>
                {!isNew && (
                  <Text
                    color="gray.500"
                    borderRadius={6}
                    bg="gray.100"
                    px={2}
                    fontWeight={700}
                    fontFamily="CeraPro"
                    fontSize={["10px", "10px", "10px", "12px"]}
                    textAlign="center"
                    className="!ms-px-2 me-3"
                  >
                    Sponsored
                  </Text>
                )}
              </HStack>

              <Box placeSelf="flex-center" className="pr-2">
                <Link
                  fontFamily="CeraPro"
                  fontWeight={500}
                  fontSize={["14px", "16px"]}
                  color="#117BD4"
                  as={NextLink}
                  href={`/${path}/${slug}`}
                  _focus={{ bg: "white", textDecoration: "none" }}
                  _active={{ bg: "white", textDecoration: "none" }}
                  _hover={{ bg: "white", textDecoration: "none" }}
                >
                  <div className="flex justify-center items-center gap-1">
                    <div className="whitespace-nowrap">See all</div>
                    <Icon
                      as={HiArrowNarrowRight}
                      boxShadow={8}
                      color="#117BD4"
                    />
                  </div>
                </Link>
              </Box>
            </Box>

            <Box w="100%">
              <Slider {...sponsoredSettings}>
                {products.map((i) => {
                  return (
                    <ProductCard
                      key={i.id}
                      id={i.id}
                      slug={i.slug}
                      name={i.name}
                      image={i.imageUrl}
                      lowestPrice={i.lowestPrice}
                      highestPrice={i.highestPrice}
                      rating={i.rating}
                      isOutOfStock={i.isOutOfStock}
                    />
                  );
                })}
              </Slider>
            </Box>
          </VStack>
        </Container>
      )}
    </>
  );
};

export default ProductRows;
