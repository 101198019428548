import { Box, Icon, Image } from '@chakra-ui/react';
import { useState } from 'react';
import { GiPlainCircle } from 'react-icons/gi';
import Slider from 'react-slick';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { onClickBanner } from 'lib/EventTracker';

const StoreBanner = ({ banners, category }) => {
  const [currentSlide, setCurrentSlide] = useState();
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (prev, next) => setCurrentSlide(next),
    customPaging: i => <Icon as={GiPlainCircle} boxSize='10px' color={i === currentSlide ? '#117BD4' : '#E9F2FA'} />
  };

  return (
    <Box maxW='100%' px={['0px', '0px']}>
      <Slider {...settings}>
        {banners && banners.length != 0 && banners.map((data) => {          
          return (
            <Box
              h='100%'
              w='100%'
              as='button'
              key={data.image_url}
              bg='white'
              onClick={() => onClickBanner(data?.link, data?.image_url, category)}
              _hover={{border:'none', bg:'white'}}
              _focus={{border:'none', bg:'white'}}
              _active={{border:'none', bg:'white'}}
            >
              <Image src={data?.image_url} alt='' w='100%' objectFit='fill' />
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}

export default StoreBanner;