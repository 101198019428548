import { Box, Container, VStack, Image, Spacer } from '@chakra-ui/react';
import { useRootContext } from 'src/contexts/RootContext';

const StoreStaticBannersMobile = () => {
  const rootContext = useRootContext();
  // Using desktop banners for this
  const static_banner_1 = rootContext?.cmsData?.static_banner_top_1;
  const static_banner_2 = rootContext?.cmsData?.static_banner_top_2;

  const static_banner_1_link = rootContext?.cmsData?.static_banner_1_link;
  const static_banner_2_link = rootContext?.cmsData?.static_banner_2_link;

  return (
    <Container  minW='100%' px='12px' pt='10px' pb={0}>
      <VStack w='100%'>
        <Box 
          as='button'
          h='50%' 
          w='100%'
          onClick={() => {
            if (static_banner_1_link) {
              window.open(static_banner_1_link, '_blank');
            }
          }}
          _hover={{border:'none', bg:'white'}}
          _focus={{border:'none', bg:'white'}}
          _active={{border:'none', bg:'white'}}
        >
          <Image src={static_banner_1.link} minW='100%' alt='' objectFit='fill' />
        </Box>
        <Spacer />
        <Box 
          as='button'
          h='50%' 
          w='100%'
          onClick={() => {
            if (static_banner_2_link) {
              window.open(static_banner_2_link, '_blank');
            }
          }}
          _hover={{border:'none', bg:'white'}}
          _focus={{border:'none', bg:'white'}}
          _active={{border:'none', bg:'white'}}
        >
          <Image src={static_banner_2.link} minW='100%' alt='' objectFit='fill' />
        </Box>
      </VStack>
    </Container>
  )
}

export default StoreStaticBannersMobile;
