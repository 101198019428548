import {
  Center,
  Divider,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import Link from "next/link";
import { RiStarFill } from "react-icons/ri";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import cx from "classnames";
import { useRouter } from "next/router";
import ReactGA from "react-ga4";
import { useAppDispatch, useAppSelector } from "@/src/app/hooks";
import { showModal } from "@/src/components/store/storeModal/storeModalSlice";
import { selectCart } from "@/src/components/store/cart/cartSlice";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function StoreCard({
  store_slug,
  store_name,
  logo,
  rating,
  isDragging,
  voucher_color,
  store_discount_amount,
  indefinite_run,
  is_valid,
  end_date,
}) {
  const dispatch = useAppDispatch();
  const { cartDetails } = useAppSelector(selectCart);

  const router = useRouter();
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(typeof window !== "undefined");
  }, []);

  const hasSelectedLocation =
    isClient && localStorage?.getItem("selectedLocation");

  const handleClick = (event) => {
    if (isDragging) {
      event.preventDefault();
    } else {
      if (hasSelectedLocation) {
        router.push(`/store/merchant/${store_slug}`);
      } else {
        if (cartDetails && cartDetails.total_items) {
          dispatch(showModal({ modalType: "clear-cart-modal" }));
        } else {
          dispatch(showModal({ modalType: "select-location-modal" }));
        }
      }
    }
  };

  const formatUtcToPhTime = (dateStr) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    const utcDate = moment.utc(dateStr, format);
    const phtDate = utcDate.tz("Asia/Manila");
    return phtDate.format(format);
  };

  const calculateTimeLeft = (endDate) => {
    if (!end_date || indefinite_run || !is_valid) {
      return;
    }

    let updatedEndData = formatUtcToPhTime(endDate);
    const difference = new Date(updatedEndData) - new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
    } else {
      timeLeft = {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(end_date));

  useEffect(() => {
    if (!end_date || indefinite_run || !is_valid) {
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(end_date));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div
      className={cx("h-full block relative", {
        "pointer-events-none": isDragging,
      })}
      onClick={handleClick}
    >
      {!indefinite_run && is_valid && (
        <div className="absolute top-1 left-1">
          <HStack color="white" gap="4px" justifyContent="space-evenly">
            <div className="w-6 h-5 text-xs font-bold flex items-center justify-center rounded-[4px] !mx-0 bg-[#ED2D2D] text-white">
              <Text fontFamily="CeraPro" className="max-mobile-sm:text-xs">
                {timeLeft?.days}
              </Text>
              <Text fontFamily="CeraPro" className="font-normal">
                d
              </Text>
            </div>
            <div className="w-6 h-5 text-xs font-bold flex items-center justify-center rounded-[4px] !mx-0 bg-[#ED2D2D] text-white">
              <Text fontFamily="CeraPro" className="max-mobile-sm:text-xs">
                {timeLeft?.hours}
              </Text>
              <Text fontFamily="CeraPro" className="font-normal">
                h
              </Text>
            </div>
            <div className="w-6 h-5 text-xs font-bold flex items-center justify-center rounded-[4px] !mx-0 bg-[#ED2D2D] text-white">
              <Text fontFamily="CeraPro" className="max-mobile-sm:text-xs">
                {timeLeft?.minutes}
              </Text>
              <Text fontFamily="CeraPro" className="font-normal">
                m
              </Text>
            </div>
            <div className="w-6 h-5 text-xs font-bold flex items-center justify-center rounded-[4px] !mx-0 bg-[#ED2D2D] text-white">
              <Text fontFamily="CeraPro" className="max-mobile-sm:text-xs">
                {timeLeft?.seconds}
              </Text>
              <Text fontFamily="CeraPro" className="font-normal">
                s
              </Text>
            </div>
          </HStack>
        </div>
      )}
      {(indefinite_run || is_valid) && (
        <div className="absolute top-0 right-[72px] text-white max-mobile-sm:right-[62px]">
          <VStack
            clipPath="polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 20px), 0 100%)"
            bg={voucher_color}
            px={0}
            py={0}
            pt={0}
            borderRadius="0px"
            placeSelf="flex-start"
            zIndex={2}
            w={["58px"]}
            h={["80px"]}
            alignItems="center"
            justifyContent="start"
            position="absolute"
            className="!rounded-tr-md"
          >
            <Text
              className="text-sm !mt-0 translate-y-1 max-mobile-sm:text-xs max-desktop-sm:translate-y-0 max-desktop-sm:pt-1"
              fontFamily="CeraPro"
            >
              GET
            </Text>
            <Text
              className="font-extrabold text-sm !mt-0 max-mobile-sm:text-xs"
              fontFamily="CeraPro"
            >
              ₱{store_discount_amount?.toLocaleString()}
            </Text>
            <Text
              className="text-sm font-extrabold !mt-0 -translate-y-1 max-mobile-sm:text-xs max-desktop-sm:translate-y-0"
              fontFamily="CeraPro"
            >
              OFF
            </Text>
          </VStack>
        </div>
      )}
      <VStack
        spacing="8px"
        pt={["12px", "22px"]}
        pb="px"
        px={["12px", "22px"]}
        w={isDesktop ? "95%" : "98%"}
        h="auto"
        minH={["280px", "300px"]}
        borderRadius="8px"
        border={"1px solid #E0E9EE"}
        boxShadow={"md"}
        className="cursor-pointer"
      >
        <div className="cursor-pointer">
          <VStack spacing="-20px" paddingTop={["30px", "28px"]}>
            <Center w="100%">
              <Image
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${logo}`}
                w="100%"
                h="100%"
                maxW={["120px", "150px"]}
                maxH={["120px", "150px"]}
                minH={["120px", "150px"]}
                minW={["120px", "150px"]}
                objectFit="cover"
                alt={store_name}
                className="rounded-full border border-[#E0E9EE]"
              />
            </Center>
          </VStack>
        </div>

        <div className="flex flex-col justify-between flex-grow w-full pt-3">
          <HStack
            w="100%"
            minH="44px"
            className="flex !items-start"
            placeSelf="flex-end"
          >
            <Text
              px="0px"
              w="100%"
              fontSize={["15px", "16px"]}
              pt="0px"
              fontWeight={500}
              fontFamily="CeraPro"
              lineHeight={["16px", "18px"]}
              color="#214151"
              textAlign="left"
              noOfLines={[2, 2, 2, 2]}
            >
              {store_name}
            </Text>

            <HStack placeSelf="flex-center">
              {Number(rating) && (
                <>
                  <Icon
                    as={RiStarFill}
                    color="#E4C109"
                    boxSize="16px"
                    className={cx("mb-1", { "!mb-0": !isDesktop })}
                  />
                  <Text
                    fontFamily="CeraPro"
                    fontWeight={500}
                    fontSize={["12px", "14px"]}
                    color="#214151"
                    verticalAlign="bottom"
                    className="!ms-0.5"
                  >
                    {Number(rating).toFixed(1)}
                  </Text>
                </>
              )}
            </HStack>
          </HStack>

          <div className="flex-grow flex flex-col items-end justify-end">
            <Divider color="#E0E9EE" />

            <HStack w="100%" pb={2}>
              <Spacer />

              <Link
                href={`/store/merchant/${store_slug}`}
                className={cx("cursor-pointer w-full rounded-full", {
                  "pointer-events-none": !hasSelectedLocation,
                })}
              >
                <Text
                  className="bg-[#117BD4] text-sm p-2 text-white mt-2.5 mb-0.5 font-medium rounded-full text-center h-9 hover:bg-opacity-90"
                  fontFamily="CeraPro"
                >
                  View Store
                </Text>
              </Link>
            </HStack>
          </div>
        </div>
      </VStack>
    </div>
  );
}
