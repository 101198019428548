import { Box, Text, VStack, Link, Icon } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import NextLink from 'next/link';
import Slider from 'react-slick';
import ProductCard from 'src/components/store/storeProducts/templates/shared/ProductCard';
import { useRootContext } from 'src/contexts/RootContext';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import getPriceRange from 'src/modules/getPriceRange';
import { BESTSELLER_TAG, NEW_TAG } from 'lib/Constants';
import { HiArrowNarrowRight } from 'react-icons/hi';

export default function ProductGenericSlider({ label, filter }) {
  const [filteredProducts, setFilteredProducts] = useState();
  const [slug, setSlug] = useState();
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const rootContext = useRootContext();
  const _bestSellers = rootContext?.bestSellers;
  const _newProducts = rootContext?.newProducts;

  useEffect(() => {
    if (filter == 'is_bestseller' && _bestSellers) {
      setSlug(BESTSELLER_TAG)
      setFilteredProducts(_bestSellers);
    } else if (filter == 'is_new') {
      setSlug(NEW_TAG)
      setFilteredProducts(_newProducts);
    }
  }, [filter, _bestSellers, _newProducts]);

  const slidesToShow = useMemo(() => {
    if (isDesktop == true) {
      return filteredProducts?.length < 6 ? filteredProducts?.length : 6;
    } else {
      return filteredProducts?.length < 2 ? filteredProducts?.length : 2;
    }
  }, [filteredProducts, isDesktop]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 3,
    slidesToShow: slidesToShow,
    arrows: isDesktop ? true : false,
    slidesToScroll: isDesktop ? 2 : 1,
    swipeToSlide: true,
  };
  
  return (
    <VStack w='100%' py={2} px={['12px', 0]}>
      <Box w="full" className='flex justify-between items-center' pb={3}>
        <Text color='#214151' fontWeight={500} fontFamily='CeraPro' fontSize={['20px','22px']} lineHeight='24px' textAlign='left'>
          {label}
        </Text>
        <Box placeSelf='flex-center' className='pr-2'>
          <Link
            fontFamily='CeraPro'
            fontWeight={500}
            fontSize={['14px','16px']}
            color='#117BD4'
            as={NextLink} 
            href={{
              pathname: `${process.env.NEXT_PUBLIC_URL}store/[slug]`,
              query: { slug: slug, title: label },
            }}
            _focus={{ bg: 'white', textDecoration: 'none' }}
            _active={{ bg: 'white', textDecoration: 'none' }}
            _hover={{ bg: 'white', textDecoration: 'none' }}
          > 
            <div className='flex justify-center items-center gap-1'>
              <div className='whitespace-nowrap'>See all</div>
              <Icon as={HiArrowNarrowRight} boxShadow={8} color='#117BD4' />
            </div>
          </Link>
        </Box>
      </Box>

      <Box w='100%'>
        <Slider {...settings}>
          {filteredProducts && filteredProducts.map((p) => {
            const range = getPriceRange(p.stores);
            return (
              <ProductCard
                key={p.id}
                id={p.id}
                slug={p.slug}
                name={p.name}
                image={p.image_url}
                lowestPrice={range?.lowest_price}
                highestPrice={range?.highest_price}
                rating={p.rating}
                isOutOfStock={p.is_out_of_stock}
              />
            )
          })}
        </Slider>
      </Box>
    
    </VStack>
  )
}