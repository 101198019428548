import { Box, Container, HStack, Icon, Image, Spacer, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import { useRootContext } from 'src/contexts/RootContext';
import { GiPlainCircle } from 'react-icons/gi';
import Slider from 'react-slick';
import jwt from 'jsonwebtoken';
import { USER_TOKEN } from 'lib/Constants';
import verifyToken from 'src/modules/verifyToken';
import { onClickBanner } from 'lib/EventTracker';

const StoreHomePageCarouselDesktop = () => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const rootContext = useRootContext();
  const [currentSlide, setCurrentSlide] = useState();
  const carousel_top_desktop = rootContext?.cmsData?.multiple_banners_top;
  const static_banner_1 = rootContext?.cmsData?.static_banner_top_1;
  const static_banner_2 = rootContext?.cmsData?.static_banner_top_2;
  const static_banner_1_link = rootContext?.cmsData?.static_banner_1_link;
  const static_banner_2_link = rootContext?.cmsData?.static_banner_2_link;

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (prev, next) => setCurrentSlide(next),
    customPaging: i => <Icon as={GiPlainCircle} boxSize='10px' color={i === currentSlide ? '#117BD4' : '#E9F2FA'} />
  };

  return (
    <>
      {carousel_top_desktop && isDesktop && static_banner_1 && static_banner_2 &&
        <Container  minW='100%' px='60px' pt='10px'>
          <HStack w='100%' p={0} spacing={0}>
            <Box w='67%' h='100%'>
              <Slider {...bannerSettings}>
                {carousel_top_desktop.slice().sort((a, b) => (a.name).trim().localeCompare((b.name).trim())).map((data) => (
                  <Container 
                    as='button'
                    h='100%' 
                    minW='100%'
                    key={data.image_url}
                    onClick={() => onClickBanner(data?.link, data?.image_url, 'Homepage Carousel Banners Top')}
                    _hover={{border:'none', bg:'white'}}
                    _focus={{border:'none', bg:'white'}}
                    _active={{border:'none', bg:'white'}}
                    px={0}
                  >
                    <Image src={data.image_url} alt='' w='100%' h='auto' objectFit='cover' />
                  </Container>
                ))}
              </Slider>
            </Box>
            <VStack w='33%' p={0} spacing='5px' placeSelf='flex-start'>
              <Container 
                as='button'
                h='100%' 
                px={2}
                minW='100%'
                onClick={() => onClickBanner(static_banner_1_link ?? null, static_banner_1?.link, 'Homepage Static Banner 1')}
                _hover={{border:'none', bg:'white'}}
                _focus={{border:'none', bg:'white'}}
                _active={{border:'none', bg:'white'}}
                placeSelf='flex-end'
              >
                <Image src={static_banner_1.link} h='auto' alt='' objectFit='fill' />
              </Container>
              <Spacer />
              <Container
                as='button'
                h='100%' 
                minW='100%'
                px={2}
                onClick={() => onClickBanner(static_banner_2_link ?? null, static_banner_2?.link, 'Homepage Static Banner 2')}
                _hover={{border:'none', bg:'white'}}
                _focus={{border:'none', bg:'white'}}
                _active={{border:'none', bg:'white'}}
                placeSelf='flex-end'
              >
                <Image src={static_banner_2.link} h='auto' alt='' objectFit='fill' />
              </Container>
            </VStack>
          </HStack>
        </Container>
      }
    </>
  )
}

export default StoreHomePageCarouselDesktop;