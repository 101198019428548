import Head from "next/head";
import LocationLogo from "src/assets/location.png";
import ShoppingBagLogo from "src/assets/shopping-bag.png";
import MotorcycleLogo from "src/assets/motorcycle.png";
import time from "src/assets/time.png";
import delivery from "src/assets/delivery.png";
import multiple_stores from "src/assets/multiple_stores.png";
import products from "src/assets/products.png";
import voucher from "src/assets/voucher.png";
import {
  VStack,
  Text,
  Stack,
  Box,
  Center,
  Image,
  Heading,
} from "@chakra-ui/react";
import { TALK_ABOUT_THIRST } from "@/constants/aboutUs";

export default function HowItWorks() {
  return (
    <VStack w="100%" pt="36px" spacing={["12px", "25px"]}>
      <VStack w="100%" bg="#117BD4" py="55px" spacing={["12px", "48px"]}>
        <Heading as="h2" className="how-it-works-title">
          Let&apos;s talk about Thirst
        </Heading>
        <Stack direction={["column", "column", "column", "row"]} gap="50px">
          {TALK_ABOUT_THIRST.map((x, index) => {
            return (
              <VStack
                spacing={["0px", "10px"]}
                placeSelf={[
                  "center",
                  "center",
                  "center",
                  "center",
                  "flex-start",
                ]}
                className="w-fit"
                key={index}
              >
                <Center borderRadius="100%" w="100px" h="100px" bg="#E9F2FA">
                  <Image src={x.icon} w="45px" h="45px" />
                </Center>
                <Heading
                  as="h3"
                  className="text-white break-words"
                  textAlign="center"
                >
                  {x.title}
                </Heading>
              </VStack>
            );
          })}
        </Stack>
      </VStack>
    </VStack>
  );
}
