import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import { useRootContext } from "src/contexts/RootContext";
import { GiPlainCircle } from "react-icons/gi";
import Slider from "react-slick";
import jwt from "jsonwebtoken";
import { USER_TOKEN } from "lib/Constants";
import verifyToken from "src/modules/verifyToken";
import { onClickBanner } from "lib/EventTracker";
import { isEmpty } from "lodash";

const HomepageStaticBanners = ({ staticBanners }) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const rootContext = useRootContext();
  const [currentSlide, setCurrentSlide] = useState();

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (prev, next) => setCurrentSlide(next),
    customPaging: (i) => (
      <Icon
        as={GiPlainCircle}
        boxSize="10px"
        color={i === currentSlide ? "#117BD4" : "#E9F2FA"}
      />
    ),
  };

  return (
    <>
      {staticBanners && !isEmpty(staticBanners) && (
        <Container minW="100%" px={["0px", "60px"]} pt="10px">
          <VStack w="100%" p={0} placeSelf="flex-start" spacing={[1, 3]}>
            {staticBanners[0]?.isValid && (
              <Container
                as="button"
                h="100%"
                minW="100%"
                w="100%"
                onClick={() =>
                  onClickBanner(
                    staticBanners[0]?.bannerLink ?? null,
                    staticBanners[0]?.bannerImage,
                    "Homepage Static Banner 1"
                  )
                }
                _hover={{ border: "none", bg: "white" }}
                _focus={{ border: "none", bg: "white" }}
                _active={{ border: "none", bg: "white" }}
                placeSelf="flex-end"
                px={0}
              >
                <Image
                  src={staticBanners[0]?.bannerImage}
                  h="auto"
                  alt=""
                  objectFit="cover"
                  w="full"
                  className="!mx-0 !px-0"
                />
              </Container>
            )}
            <Spacer />
            {staticBanners[1]?.isValid && (
              <Container
                as="button"
                h="100%"
                minW="100%"
                w="100%"
                onClick={() =>
                  onClickBanner(
                    staticBanners[1]?.bannerLink ?? null,
                    staticBanners[1]?.bannerImage,
                    "Homepage Static Banner 2"
                  )
                }
                _hover={{ border: "none", bg: "white" }}
                _focus={{ border: "none", bg: "white" }}
                _active={{ border: "none", bg: "white" }}
                placeSelf="flex-end"
                px={0}
                mt={0}
                py={0}
              >
                <Image
                  src={staticBanners[1]?.bannerImage}
                  h="auto"
                  alt=""
                  objectFit="cover"
                  w="full"
                  className="!mx-0 !px-0"
                />
              </Container>
            )}
          </VStack>
        </Container>
      )}
    </>
  );
};

export default HomepageStaticBanners;
