import { Box, Container, VStack, Text, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useRootContext } from 'src/contexts/RootContext';

const StoreHomepageBlogContent = () => {
  const rootContext = useRootContext();
  const [showMore, setShowMore] = useState(true);
  const title = rootContext?.cmsData?.blog_title;
  const body = rootContext?.cmsData?.blog_body;

  if (!body && !title) return;

  return (
    <Container minW='100%' px={['22px','22px','22px','20%']} py={['20px','20px','20px','40px']}>
      <VStack w='100%'>
        <Text color='#214151' fontWeight={500} fontFamily='CeraPro' fontSize={['24px','24px','24px','28px']}  w='100%' textAlign='center'>
          {title}
        </Text>

        {body && body.length <= 1000 ?
          <Box 
            as='span' 
            color='#214151' 
            fontWeight={300} 
            pt={3} 
            fontFamily='CeraProLight' 
            fontSize={['14px','14px','14px','16px']}  
            w='100%' 
            textAlign='center'
            dangerouslySetInnerHTML={{ __html: body.slice(0, 600) }} 
          />
          :
          <>
            {!showMore && 
              <Box 
                as='span' 
                color='#214151' 
                fontWeight={300} 
                pt={3} 
                fontFamily='CeraProLight' 
                fontSize={['14px','14px','14px','16px']}  
                w='100%' 
                textAlign='center'
                dangerouslySetInnerHTML={{ __html: body?.slice(0, 600) }} 
              />
            }
            {showMore && 
              <Box 
                as='span' 
                color='#214151' 
                fontWeight={300} 
                pt={3} 
                fontFamily='CeraProLight' 
                fontSize={['14px','14px','14px','16px']}  
                w='100%' 
                textAlign='center'
                dangerouslySetInnerHTML={{ __html: body }} 
              />
            }
          </>
        }

        <Button
          color='#117BD4'
          fontFamily='CeraPro' 
          onClick={() => setShowMore(!showMore)}
          fontWeight={500}
          bg='white'
          fontSize={['12px','12px','12px','14px']} 
          p={0}
          _hover={{ bg:'white', border:'none' }}
          _active={{ bg:'white', border:'none' }}
          _focus={{ bg:'white', border:'none' }}
        >
          {showMore ? 'See Less' : 'See More'}
        </Button>
      </VStack>
    </Container>
  )
}

export default StoreHomepageBlogContent;
