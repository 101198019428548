import { Box, Button, Center, HStack, Heading, IconButton, Image, Link, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import LocationMarkerIcon from 'src/assets/icons/location-marker.svg';
import SelectLocationIcon from 'src/assets/icons/select-location.svg';
import ThirstLogo from 'src/assets/icons/thirst-logo-plain.png';
import { selectCart } from 'src/components/store/cart/cartSlice';
import { selectCategories } from 'src/components/store/categories/categoriesSlice';
import { showModal } from 'src/components/store/storeModal/storeModalSlice';
import { selectStoreProducts } from 'src/components/store/storeProducts/storeProductsSlice';
import useMediaQuery from 'src/hooks/useBetterMediaQuery';
import ReactGA from 'react-ga4';

const StoreHeader = () => {
  const router = useRouter();
  const DROPDOWN_PLACEHOLDER = 'Set delivery location';
  const dispatch = useAppDispatch();
  const [selectedCity, setSelectedCity] = useState(DROPDOWN_PLACEHOLDER);
  const { categories } = useAppSelector(selectCategories);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const { products } = useAppSelector(selectStoreProducts);
  const { cartDetails } = useAppSelector(selectCart);

  useEffect(() => {
     const location = JSON.parse(localStorage.getItem('selectedLocation'));

    if (location) {
      setSelectedCity(location?.city);
    } else {
      setSelectedCity(DROPDOWN_PLACEHOLDER);
    }
  });

  const onSetLocationClick = () => {
    ReactGA.event({ category: 'Location', action: 'Location Selector Clicks' });
    if (cartDetails && cartDetails.total_items) {
      dispatch(showModal({ modalType: 'clear-cart-modal' }));
    } else {
      dispatch(showModal({ modalType: 'select-location-modal' }));
    }
  } 

  return (
    <VStack spacing={0} w='100%' px={0}>
      {isDesktop &&
        <Box placeSelf='flex-end' pt='40px' pr={4}>
          <Text
            fontSize='14px'
            fontWeight={500}
            fontFamily='CeraPro'
            color='#117BD4'
            placeSelf='flex-start'
          >
            <Link
              href='/merchant/register'
              onClick={() => {
                ReactGA.event({ category: 'Merchant Register', action: 'Merchant Register Button Click' });
              }}
              _focus={{ bg: 'white', textDecoration: 'none' }}
              _active={{ bg: 'white', textDecoration: 'none' }}
              _hover={{ bg: 'white', textDecoration: 'none' }}
            >
              Want to have a store on Thirst? Register now!
            </Link>
          </Text>
        </Box>
      }
      <Image src={ThirstLogo.src} w={['80%', '80%', '80%', '25%']} h='100%' alt='thirst-logo' />

      <Heading
        as='h1'
        color='#214151'
        fontSize={['20px','20px','20px','26px']}
        fontFamily='CeraPro'
        fontWeight={500}
        w='100%'
        lineHeight={['24px', '36px']}
        pt={['10px', '40px']}
        px={['50px', 0]}
        textAlign='center'
        pb='10px'
      >
        The only Online Liquor Marketplace in the country!
      </Heading>

      <Heading
        as='h3'
        className='store-header'
        w={['100%','35%']}
        pt={['10px', '10px']}
        px={['30px', 0]}
      >
        Order beer, wine, liquor, and more from top-rated merchants in the comfort of your own home. Shop now and discover top-shelf deals!
      </Heading>
      {!isDesktop &&
        <Box placeSelf='center' pt='0px'>
          <Button
            bg='white'
            fontSize='14px'
            fontWeight={500}
            fontFamily='CeraPro'
            color='#117BD4'
            placeSelf='center'
            as='u'
            px={0}
            pb='30px'
            onClick={() => {
              router.replace('/merchant/register');
              ReactGA.event({ category: 'Merchant Register', action: 'Merchant Register Button Click' });
            }}
            _hover={{ border: 'none', bg: 'white' }}
            _focus={{ border: 'none', bg: 'white' }}
            _active={{ border: 'none', bg: 'white' }}
          >
            Want to have a store on Thirst? Register now!
          </Button>
        </Box>
      }

      <HStack
        py={['19px','19px','19px','30px']}
        w={['90%','90%','90%','30%']}
        px='18px'
        placeSelf='center'
        borderRadius={6}
        boxShadow='lg'
      >
        <Center w='50px' h='50px' bg='#E9F2FA' borderRadius='8px'>
          <Image src={LocationMarkerIcon.src} w='22.5px' h='22.5px' objectFit='cover' alt='' />
        </Center>

        <VStack spacing={1} placeSelf='flex-start' pt={1} w='70%' pl={2}>
          <Text 
            color='#98A6AD' 
            fontSize={['14px', '16px']} 
            fontFamily='CeraPro' 
            lineHeight='18px' 
            textAlign='left' 
            w='100%'
          >
            Delivering to
          </Text>
          <Text 
            fontWeight={500} 
            fontFamily='CeraPro' 
            lineHeight='18px' 
            fontSize={['16px', '18px']} 
            color='#214151' 
            placeSelf='start' 
            textAlign='left'
          >
            {selectedCity}
          </Text>
        </VStack>

        <IconButton
          icon={<Image src={SelectLocationIcon.src} w='22.5px' h='22.5px' objectFit='cover' alt='' />}
          onClick={onSetLocationClick}
          bg='#117BD4'
          w='54.5px'
          h='48.5px'
          aria-label='location-btn'
          _focus={{ border: 'none', bg: '#117BD4' }}
          _hover={{ border: 'none', bg: '#117BD4' }}
          _active={{ border: 'none', bg: '#117BD4' }}
        />
      </HStack>
    </VStack>
  );
};

export default StoreHeader;
