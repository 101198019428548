import Homepage from "@/backend/models/core/Homepage";
import HomepageProductRows from "@/backend/models/core/HomepageProductRows";
import StorePage from "@/backend/models/core/StorePage";
import Categories from "@backend/models/core/Categories";
import Products from "@backend/models/core/Products";
import { Container, VStack } from "@chakra-ui/react";
import Banners from "backend/models/core/Banners";
import Stores from "backend/models/core/Stores";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import StoreHomePage from "src/components/store/store/templates/mobile/StoreHomePage";
import RootContext from "src/contexts/RootContext";

const Home = (props) => {
  const { asPath } = useRouter();
  const {
    cmsData,
    stores,
    homepage,
    homepageProductRows,
    automatedNewProducts,
  } = props;

  useEffect(() => {
    if (cmsData.blue_banner_text)
      localStorage.setItem("blue_banner_string", cmsData.blue_banner_text);
  }, [cmsData]);

  return (
    <RootContext.Provider value={props}>
      <Container maxW="100vw" p={0} m={0}>
        <Head>
          <title>{cmsData.seo_title}</title>
          <meta
            name="description"
            content={cmsData.seo_description}
            key="description"
          />
          <meta property="og:title" content={cmsData.seo_title} key="title" />
          <meta
            property="og:description"
            content={cmsData.seo_description}
            key="seo_description"
          />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_URL}${asPath.substring(1)}`}
            key="url"
          />
          <link rel="canonical" href={`${process.env.NEXT_PUBLIC_URL}`} />
        </Head>

        <VStack spacing="40px" w="100%" position="relative" minH="100vh">
          <StoreHomePage
            props={cmsData}
            stores={stores}
            homepage={homepage}
            homepageProductRows={homepageProductRows}
            automatedNewProducts={automatedNewProducts}
          />
        </VStack>
      </Container>
    </RootContext.Provider>
  );
};

export async function getServerSideProps(context) {
  let cmsData = [];

  const [
    { data: categories },
    { data: bestSellers },
    { data: newProducts },
    cmsDataReq,
    { data: stores },
    { data: homepage },
    { data: homepageProductRows },
    { data: automatedNewProducts },
  ] = await Promise.all([
    Categories.all(),
    Products.getBestSellers(),
    Products.getNewProducts(),
    fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/homepage`),
    StorePage.findAll(),
    Homepage.all(),
    HomepageProductRows.all(),
    HomepageProductRows.new(),
  ]);

  const [cmsDataRes] = await Promise.all([cmsDataReq.json()]);

  if (cmsDataRes && cmsDataRes.data) {
    cmsData = Object.values(cmsDataRes?.data)?.[0] ?? [];
    if (cmsData) {
      let formattedCarouselTopDesktop = [];
      if (cmsData?.multiple_banners_with_links_top) {
        const { data: desktopCarousel } = await Banners.whereCarouselId(
          parseInt(cmsData?.multiple_banners_with_links_top)
        );
        if (desktopCarousel && desktopCarousel.length > 0) {
          desktopCarousel.map((i) => {
            formattedCarouselTopDesktop.push({
              link: i.link,
              image_url: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}/${i.image_url}`,
              device: "desktop",
              name: i.name,
            });
          });
        }
      }

      let formattedCarouselBottomDesktop = [];
      if (cmsData?.multiple_banners_with_links_bottom) {
        const { data: desktopBottomCarousel } = await Banners.whereCarouselId(
          parseInt(cmsData?.multiple_banners_with_links_bottom)
        );
        if (desktopBottomCarousel && desktopBottomCarousel.length > 0) {
          desktopBottomCarousel.map((i) => {
            formattedCarouselBottomDesktop.push({
              link: i.link,
              image_url: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}/${i.image_url}`,
              device: "desktop",
              name: i.name,
            });
          });
        }
      }

      let sponsoredProducts1Array = [];
      if (cmsData?.sponsored_products_1) {
        const sponsoredProducts1IndexArray = cmsData.sponsored_products_1
          .split(",")
          .map((id) => parseInt(id));
        sponsoredProducts1Array = await Products.whereIds(
          sponsoredProducts1IndexArray
        );
      }

      let sponsoredProducts2Array = [];
      if (cmsData?.sponsored_products_2) {
        const sponsoredProducts2IndexArray = cmsData.sponsored_products_2
          .split(",")
          .map((id) => parseInt(id));
        sponsoredProducts2Array = await Products.whereIds(
          sponsoredProducts2IndexArray
        );
      }

      cmsData = {
        ...cmsData,
        multiple_banners_top: formattedCarouselTopDesktop,
        multiple_banners_bottom: formattedCarouselBottomDesktop,
        sponsored_products_1_ids: cmsData?.sponsored_products_1.split(","),
        sponsored_products_2_ids: cmsData?.sponsored_products_2.split(","),
        sponsored_products_1: sponsoredProducts1Array?.data,
        sponsored_products_2: sponsoredProducts2Array?.data,
      };
    }
  }

  return {
    props: {
      categories,
      bestSellers,
      newProducts,
      cmsData,
      stores,
      homepage,
      homepageProductRows,
      automatedNewProducts,
    },
  };
}

export default Home;
