import {
  Box,
  Button,
  Container,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import crypto from "crypto";
import React, { useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { useAppDispatch } from "src/app/hooks";
import {
  createCart,
  setCartDetails,
} from "src/components/store/cart/cartSlice";
import CategoryPicker from "src/components/store/categories/templates/shared/CategoriesPicker";
import ProductGenericSlider from "src/components/store/storeProducts/templates/shared/ProductGenericSlider";
import { useRootContext } from "src/contexts/RootContext";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import getCurrentBrowser from "src/modules/getCurrentBrowser";
import { getIPAddress, sendConversionsEvent } from "../../storeSlice";
import StoreHomePageCarouselDesktop from "../desktop/StoreHomePageCarouselDesktop";
import HowItWorks from "../shared/HowItWorks";
import StoreBanner from "../shared/StoreBanner";
import StoreHeader from "../shared/StoreHeader";
import StoreHomepageBlogContent from "../shared/StoreHomepageBlogContent";
import StoreSponsoredProducts from "../shared/StoreSponsoredProducts";
import StoreAppBar from "./StoreAppBar";
import StoreFooter from "./StoreFooter";
import StoreStaticBannersMobile from "./StoreStaticBannersMobile";
import StoresSlider from "@/components/sliders/StoresSlider";
import HomepageStaticBanners from "@/components/banners/HomepageStaticBanners";
import HomepageCarouselBanners from "@/components/banners/HomepageCarouselBanners";
import ProductRows from "@/components/product_rows/ProductRows";
import { isEmpty } from "lodash";

const StoreHomePage = ({
  props,
  stores,
  homepage,
  homepageProductRows,
  automatedNewProducts,
}) => {
  const Hash = (value) =>
    crypto.createHash("sha256").update(value).digest("hex");
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const rootContext = useRootContext();

  // Will be using desktop banners for both desktop and mobile devices
  const carousel_top = rootContext?.cmsData?.multiple_banners_top;
  const carousel_bottom_desktop = rootContext?.cmsData?.multiple_banners_bottom;

  const sponsored_products_1 = rootContext?.cmsData?.sponsored_products_1;
  const sponsored_products_1_title =
    rootContext?.cmsData?.sponsored_products_title_1;
  const sponsored_products_2 = rootContext?.cmsData?.sponsored_products_2;
  const sponsored_products_2_title =
    rootContext?.cmsData?.sponsored_products_title_2;
  const sponsored_products_1_ids =
    rootContext?.cmsData?.sponsored_products_1_ids;
  const sponsored_products_2_ids =
    rootContext?.cmsData?.sponsored_products_2_ids;
  const { carouselTop, carouselBottom, staticBanners } = homepage;

  const sponsoredOne = useMemo(() => {
    return homepageProductRows.find((x) => x.rowOrder === 1);
  }, [homepageProductRows]);

  const sponsoredTwo = useMemo(() => {
    return homepageProductRows.find((x) => x.rowOrder === 2);
  }, [homepageProductRows]);

  const sponsoredThree = useMemo(() => {
    return homepageProductRows.find((x) => x.rowOrder === 3);
  }, [homepageProductRows]);

  const remainingSponsored = useMemo(() => {
    return homepageProductRows.filter((x) => x.rowOrder > 3);
  });

  useEffect(() => {
    if (sessionStorage.getItem("is_age_verified") == null) {
      TrackViewEvent();
      onOpen();
      localStorage.removeItem("selectedLocation");
    }

    getCart();
    localStorage.removeItem("searched_substring");
    localStorage.setItem("previous_page", "Home");
  }, []);

  const getCart = async () => {
    const cart_id = localStorage.getItem("cartId");

    if (!cart_id) {
      await dispatch(createCart());
    } else {
      try {
        let response = await fetch("/api/cart/getCart", {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({ cart_id }),
        });

        response = await response.json();

        if (response) {
          let lineItems = null;
          if (response.data[0].line_items)
            lineItems = JSON.parse(response.data[0].line_items);
          const data = { ...response.data[0], line_items: lineItems };
          await dispatch(setCartDetails(data));
        }
      } catch (error) {
        await dispatch(createCart());
        return;
      }
    }
  };

  const TrackViewEvent = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.href}`,
      title: "Homepage",
    });
    try {
      const geolocation = await dispatch(getIPAddress()).unwrap();
      const browser = getCurrentBrowser();
      const raw = JSON.stringify({
        data: [
          {
            event_name: "View content",
            event_time: Math.floor(Date.now() / 1000),
            user_data: {
              ct: `${Hash(geolocation.city.replace(/[^A-Z0-9]+/gi, ""))}`,
              st: `${Hash(geolocation.regionName.replace(/[^A-Z0-9]+/gi, ""))}`,
              zp: Hash(geolocation.zip),
              country: `${Hash(geolocation.countryCode.toLowerCase())}`,
              client_ip_address: `${geolocation.query}`,
              client_user_agent: `${browser}`,
            },
            custom_data: { content_name: "Homepage" },
            event_source_url: `${window.location.href}`,
            action_source: "website",
          },
        ],
      });

      await dispatch(sendConversionsEvent({ raw }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VStack w="100%">
      <>
        <StoreAppBar />
        <Box w="100%" pt={["160px", "100px"]} pb="20px">
          <StoreHeader />
        </Box>

        <Box
          w="100%"
          px={["12px", "50px"]}
          pt={["20px", "80px"]}
          pb={["10px", "50px"]}
        >
          <Text
            color="#214151"
            fontWeight={500}
            fontFamily="CeraPro"
            fontSize={["20px", "20px", "20px", "22px"]}
            w="100%"
            textAlign="left"
            marginBottom={5}
            marginLeft={isDesktop ? 0 : 3}
          >
            Categories
          </Text>
          <CategoryPicker slidesToShowValue={isDesktop ? 10 : 4.5} />
        </Box>

        <Container
          pt={["60px", "60px", "60px", "0px"]}
          minW="100%"
          px={[0, 0, 0, "50px"]}
          pb="10px"
        >
          <StoresSlider label="Store Partners" stores={stores} />
        </Container>

        <HomepageCarouselBanners banners={carouselTop} />

        {sponsoredOne && sponsoredOne.isValid && (
          <ProductRows
            title={sponsoredOne.rowTitle}
            products={sponsoredOne.products}
            slug={sponsoredOne.rowSlug}
          />
        )}

        <HomepageStaticBanners staticBanners={staticBanners} />

        {/* {sponsored_products_2 &&
          sponsored_products_2.length > 0 &&
          isDesktop != null && (
            <StoreSponsoredProducts
              sponsored_products={sponsored_products_2}
              title={sponsored_products_2_title}
              ids={sponsored_products_2_ids ?? []}
            />
          )} */}

        {sponsoredTwo && sponsoredTwo.isValid && (
          <ProductRows
            title={sponsoredTwo.rowTitle}
            products={sponsoredTwo.products}
            slug={sponsoredTwo.rowSlug}
          />
        )}

        <Container pt="40px" minW="100%" px={[0, 0, 0, "50px"]}>
          <ProductGenericSlider label="Popular Items" filter="is_bestseller" />
        </Container>

        {sponsoredThree && sponsoredThree.isValid && (
          <Container pb="20px" minW="100%">
            <ProductRows
              title={sponsoredThree.rowTitle}
              products={sponsoredThree.products}
              slug={sponsoredThree.rowSlug}
            />
          </Container>
        )}

        <HomepageCarouselBanners banners={carouselBottom} />

        {/* <Container
          pt={["60px", "60px", "60px", "20px"]}
          minW="100%"
          px={[0, 0, 0, "50px"]}
        >
          <ProductGenericSlider label="New Products" filter="is_new" />
        </Container> */}

        {!isEmpty(automatedNewProducts) && (
          <ProductRows
            title="New Products"
            products={automatedNewProducts}
            slug="new-products"
            isNew
          />
        )}

        {remainingSponsored &&
          !isEmpty(remainingSponsored) &&
          remainingSponsored.map((product, pIdx) => {
            return (
              <React.Fragment key={pIdx}>
                {product.isValid && (
                  <ProductRows
                    title={product.rowTitle}
                    products={product.products}
                    slug={product.rowSlug}
                  />
                )}
              </React.Fragment>
            );
          })}

        <StoreHomepageBlogContent />

        <HowItWorks />
        <Container
          pt={["20px", "20px", "20px", "40px"]}
          minW="100%"
          px={[0, 0, 0, "40px"]}
        >
          <StoreFooter />
        </Container>

        <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w="95%" pl="35px" pr="0px" pt="30px" placeSelf="center">
            <Text
              className="generic-black-text"
              fontSize={["16px", "18px"]}
              lineHeight="18px"
              pb="30px"
            >
              Verify Age
            </Text>
            <ModalBody p={0}>
              <Text
                color="#214151"
                fontFamily="CeraProLight"
                fontSize={["16px", "18px"]}
                lineHeight="24px"
                pb="20px"
              >
                Are you 18 or older?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Link href="https://www.google.com/">
                <Button
                  mr={3}
                  bg="#E0E9EE"
                  _hover={{ bg: "#E0E9EE", border: "none" }}
                  _active={{ bg: "#E0E9EE", border: "none" }}
                  _focus={{ bg: "#E0E9EE", border: "none" }}
                  fontFamily="CeraPro"
                >
                  No
                </Button>
              </Link>

              <Button
                bg="#117BD4"
                color="white"
                autoFocus
                onClick={() => {
                  sessionStorage.setItem("is_age_verified", "true");
                  onClose();
                }}
                fontFamily="CeraPro"
                _hover={{ bg: "#117BD4", border: "none" }}
                _active={{ bg: "#117BD4", border: "none" }}
                _focus={{ bg: "#117BD4", border: "none" }}
              >
                Verify
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </VStack>
  );
};

export default StoreHomePage;
